import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import HeartQuote from '../components/HeartQuote';
export const _frontmatter = {
  "date": "2017-06-06",
  "title": "80 Reasons to Love the Web",
  "description": "A bunch of reasons why the web platform rocks",
  "color": "#00d2f1"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Sometimes doesn't it feel like the world is completely falling apart? So many things going wrong, all at once. You want to try to make it all go away by hiding your head in some sand like an ostrich. My preffered head-burying sand spot is my own hand-picked bubble of people I follow on twitter. Makes the world feel like my kind of place - where `}<em parentName="p">{`everyone`}</em>{` pretty much only cares about UI development and fun things like drones and 3D printing. But wait what's this? Politics in my feed! People I respect bickering over tech. Another shady company screwing its employees at IPO. It doesn't take much for it to feel like everything is going downhill.`}</p>
    <p>{`But there's a cure to the negativity and despair. One that kicks your brain into its feel-good chemical production mode - and has the side benefit of not landing you in prison. The fix isn't more fine-tuning of your sand/bubble. It's gratitude.`}</p>
    <p>{`According to `}<a parentName="p" {...{
        "href": "https://www.theladders.com/p/21219/neuroscience-4-rituals-happy"
      }}>{`new neuroscience findings`}</a>{` just the small act of `}<em parentName="p">{`looking`}</em>{` for things to be grateful for - even if you don't find anything - boosts your dopamine & serotonin levels. Thanks science! `}</p>
    <p>{`Those of us who live & work on the web really have so much to be grateful for. I emailed and asked my readers a while back `}<strong parentName="p">{`"what do you love about the web?"`}</strong>{`. The replies were awesome. So I wanted to collect & share them here for any web devs/designers/etc who might need a little boost today. Here are 80 reasons to love the web:`}</p>
    <HeartQuote by="pamela" mdxType="HeartQuote">
I love the web because it's the first thing that got me interested in computing. The visceral feeling of making is what has kept me going. I strive to share this feeling to other women, ladies, and girls that want to be involved in the industry!  
    </HeartQuote>
    <HeartQuote by="Stephen" mdxType="HeartQuote">
Accessible virtually everywhere, no real barrier of entry to learn new stuff, make new stuff and have the opportunity to change some industry or piece of the world for the better.
    </HeartQuote>
    <HeartQuote by="Razvan" mdxType="HeartQuote">
I love all the possibilities it gives you. Since technology is such a big part of our lives, it is great to be the one who helps it integrate more in our daily habbits. Tinkering, creating, discovering, even failing and starting over a project give you the feeling that you've grown and learn a lot. Web is a constant learning experience and challenge.
    </HeartQuote>
    <HeartQuote by="Luke" mdxType="HeartQuote">
I love that it's basically the entirety of human knowledge, history, learning, and experience at the finger tips of so many! The ability to see and experience other cultures and people groups is nothing short of miraculous. 
    </HeartQuote>
    <HeartQuote by="Michael" mdxType="HeartQuote">
I love the ease and flexibility of the web... the ease of access to knowledge, as well as the ease of getting started.  How many of us developers simply started off by building a website using "View Page Source" as our manual?  The community is awesome and it's fun to be a part of.
    </HeartQuote>
    <HeartQuote by="Rob" mdxType="HeartQuote">
The web is the wild west of this generation!  It's so open, with literally endless possibilities, and that's what I love about the web.  
    </HeartQuote>
    <HeartQuote by="Jason" mdxType="HeartQuote">
I love how the web has made us all Astronauts. We're able to easily share knowledge and do things we never thought we we'd be capable of. It's given us the ability to be more well rounded and expressive. 
    </HeartQuote>
    <HeartQuote by="Owen" mdxType="HeartQuote">
The web is an ultimately flexible platform and it enables us to get content and applications into _almost_ anyone’s hands. It works as an agent of education and change and those of us lucky enough get to work with and on it every day.
    </HeartQuote>
    <HeartQuote by="David" mdxType="HeartQuote">
what I love most is that it is impossible to be relevant without constantly exploring and experimenting. Things change so fast, usually for the better, and there are a TON of amazing things you can do (that get easier and more powerful every day
    </HeartQuote>
    <HeartQuote by="Tony" mdxType="HeartQuote">
There is no limit in terms of applications development. Many features, technologies and tools are added to the browsers to facilitate the Developers' lives.
    </HeartQuote>
    <HeartQuote by="Will" mdxType="HeartQuote">
The thing I love most about the web is how connected it has made us all. I love how easy it is to now stay in touch with friends wherever they are, share ideas and iterate on them quickly, and work from the comfort of my home so I can be more connected in person with my family!
    </HeartQuote>
    <HeartQuote by="Ian" mdxType="HeartQuote">
I love how the web makes the world a better place by opening up all of the information for everyone to access.
    </HeartQuote>
    <HeartQuote by="Terry" mdxType="HeartQuote">
I love the web from a developer’s experience because it is constantly changing and improving.  Yeah, sometimes all the change is a pain, but with each change comes new capabilities which drives us to do more and better.
    </HeartQuote>
    <HeartQuote by="Daniel" mdxType="HeartQuote">
 I love the web because it's an amazing and pretty universal communication tool across platforms, devices and countries! The bar to entry in learning how to command this mighty tool it is fairly low compared to the likes of Java and C languages. It's growth over the last many years has been amazing, and pretty much anything is on the table now as far as achievability. The dominance of JavaScript has solidified in the browser and is overtaking other programming environments like mobile, desktop, IoT, robots, etc. It's flexibility allows users to "choose their own adventure" as far as how to implement what they need. And the amount of tools to help you along is incredibly enabling, in that you can do SO MUCH MORE than you used to be able to in SO MUCH LESS TIME, because you are following paths that have been well tread.
    </HeartQuote>
    <HeartQuote by="Jessica" mdxType="HeartQuote">
What I love the most about the Web is the power to connect that it provides to all its users. To connect, and to share. That is how the journey started for me, over 12 years ago, when I created my first website in good old Geocities.
    </HeartQuote>
    <HeartQuote by="Brett" mdxType="HeartQuote">
I love the web. It is the ultimate cross-platform, open run time client, where app updates are seamlessly deployed to users on each visit.
    </HeartQuote>
    <HeartQuote by="Sam" mdxType="HeartQuote">
The community it creates! We can find anything we want, people to relate to, from all across the globe. As a lover of my career of web development, I'm able to gain access to this community and learn and advance every day!
    </HeartQuote>
    <HeartQuote by="Jeremy" mdxType="HeartQuote">
My favorite thing about the web is that because everybody has a web browser, everybody has a programming language on their computer, so you don't have to worry about enterprise installations, software licenses, you can easily deploy software to anybody and start solving their problems.
    </HeartQuote>
    <HeartQuote by="Hunter" mdxType="HeartQuote">
I love the amounts of intellectual property that is stored on the web.  I love the creativity and professional software that goes on the web. Most importantly though, I love the hard work of people's daily lives that gets entangled web.  You can ignore social media etc. and see the value in quick books, google sheets, and every piece of software that people put countless hours into.
    </HeartQuote>
    <HeartQuote by="Jerry" mdxType="HeartQuote">
 I love how much information is instantly available from the web!
    </HeartQuote>
    <HeartQuote by="Adam" mdxType="HeartQuote">
When I was in college 8 years ago I was a marketing major. One of my final projects was to create a website to showcase some of the marketing projects that I completed throughout my major. The professor offered up a lot of cool tools (wysiwyg editors) for us to use, but as I started to play with them became more interested in the "How" this works rather than the "Why" and the "What" for.  I started messing around with some simple HTML markup, moved my way into CSS to make things pretty, and then fumbled around with jQuery to animate some stuff. By the end of that project I realized that my marketing major was going to be pretty useless as I'd be pursuing a career in web development. I've been working in the space ever since and what I love most about the web is that, just about everyday I get to feel that same spark of excitement that I felt back in college of learning something new, unique and usually really cool. Hence why I'm stoked to learn more about flexbox, angular2, and so many more awesome technologies that make our jobs so versatile and awesome! 
    </HeartQuote>
    <HeartQuote by="Mario" mdxType="HeartQuote">
I love the Ecosystem around javascript. But more so the community behind it. People like you that want to make the web better for the rest of us. I want to join in and be part of that community.
    </HeartQuote>
    <HeartQuote by="Jeffrey" mdxType="HeartQuote">
I love the web because as a software engineer, I can always find the solution to a problem that I’m having.
    </HeartQuote>
    <HeartQuote by="Bryce" mdxType="HeartQuote">
I love the web because it's transformed my life and the lives of many others. Growing up, I preferred working on my older brother's computer building things than hanging with friends. I'm excited to get back into it. 
    </HeartQuote>
    <HeartQuote by="Jerry" mdxType="HeartQuote">
I love the web because it is endless information. You don't understand it, just google it and there is lots of articles and websites about what you are looking for. You aren't the only one to think about the query so you will never come up empty!
    </HeartQuote>
    <HeartQuote by="Henry" mdxType="HeartQuote">
The thing I love about the web most is open source software!
    </HeartQuote>
    <HeartQuote by="Matt" mdxType="HeartQuote">
 I love that the web has become a place for creativity. If you can think it, but don't know how to do it, the web is your answer.
    </HeartQuote>
    <HeartQuote by="Rogelio" mdxType="HeartQuote">
I love the way the web was defined from the beginning to be a web of documents (hyperlinks).  And now we still use them as it was originally intended, but also in many smart ways such as history tokens in single-page-applications.
    </HeartQuote>
    <HeartQuote by="Elliot" mdxType="HeartQuote">
I love that really smart people are willing to share really clever tutorials for free ;)
    </HeartQuote>
    <HeartQuote by="Eric" mdxType="HeartQuote">
I have been a developer for many years and what I love most recently about the web is the ability to create very rich applications cross platform from desktop to mobile and flexbox is another example of the technologies that continues to make that easier.
    </HeartQuote>
    <HeartQuote by="Benjamin" mdxType="HeartQuote">
The awesome web dev community! 
    </HeartQuote>
    <HeartQuote by="Jeremy" mdxType="HeartQuote">
I love all the information available at our finger tips.
    </HeartQuote>
    <HeartQuote by="Kenny" mdxType="HeartQuote">
What I love about the web is that I have access to a million lifetimes of knowledge. And that is what drives my unquenchable search to know more. That is also why I love development, it gives me the opportunity to unleash my creativity and turn ideas into reality.
    </HeartQuote>
    <HeartQuote by="Eric" mdxType="HeartQuote">
I love the fact that I can create whatever my imagination conjurs. Even if I don't know how to do it, there are a lot of resources available in the dev community at my finger tips.
    </HeartQuote>
    <HeartQuote by="Daniel" mdxType="HeartQuote">
What I love about the web is that the platform is available to such a huge audience and improves a lot of lives especially in developing countries through instant access to education.
    </HeartQuote>
    <HeartQuote by="Nathaniel" mdxType="HeartQuote">
I love how the web is constantly moving forward. Flexbox is available because of developers wanting to do hard things and then creating new standards that all of us can benefit from.
    </HeartQuote>
    <HeartQuote by="Arturo" mdxType="HeartQuote">
I love the constant leaning. JS fatigue is not an issue for me, I welcome it. 
    </HeartQuote>
    <HeartQuote by="Ryan" mdxType="HeartQuote">
I love the web because it makes sharing ideas like flexbox so easy.
    </HeartQuote>
    <HeartQuote by="Chad" mdxType="HeartQuote">
What I love about the web is that enables folks to realize their hopes/dreams/aspirations with much less friction.
    </HeartQuote>
    <HeartQuote by="Tim" mdxType="HeartQuote">
The greatest thing I love about the web is that the web is free.
    </HeartQuote>
    <HeartQuote by="Matthew" mdxType="HeartQuote">
I love that we are converging (finally) on the goals of web standards. Javascript libraries/frameworks like React and Angular 2 are providing a way for developers to build amazing applications in plain old javascript (or typescript). Furthermore, Full-Stack Javascript development is now a thing, and enables us dev to build cross-platform applications in essentially one language which is insane! Exciting times!
    </HeartQuote>
    <HeartQuote by="Filip" mdxType="HeartQuote">
I love the non-Breaking Nature of the web, with progressive enhancement and the enormous reach and potential. 
    </HeartQuote>
    <HeartQuote by="Alex" mdxType="HeartQuote">
What I love about the internet is it is the last frontier easily accessible by literally anyone.
    </HeartQuote>
    <HeartQuote by="Chyngyz" mdxType="HeartQuote">
What I love about web is it's inconsistency. It always pushes you to learn new things, though sometimes it makes you hate it, but new things are new adventures. Adventures are fun. 
    </HeartQuote>
    <HeartQuote by="Carlos" mdxType="HeartQuote">
During years a frustrating part of developing was the lack of standards, or rather the lack of browsers following the standards, making it very difficult to create a we application for all the browsers. I think what excites me the most is that nowadays, although things like cross browser compatibility is still a thing, it has been greatly reduce thanks to browsers now following the standards. All of this just to say that what excites me  the most is that now you don't need to be a guru of hacking JavaScript/CSS to make the web work. Thanks to frameworks and standards, the web has become way more friendly and easy to use. And I can't wait to see what the future brings us, given that developing awesome applications is becoming easier and more accessible to anyone who wants to learn it!!!
    </HeartQuote>
    <HeartQuote by="Josh" mdxType="HeartQuote">
I love how easy it is to get started developing on the web. A browser and a text editor is all you need! And with something like codepen or Github Pages you can quickly show off your work to the world!
    </HeartQuote>
    <HeartQuote by="Dmitriy" mdxType="HeartQuote">
How fast js evolves lately
    </HeartQuote>
    <HeartQuote by="Bran" mdxType="HeartQuote">
I love that you can launch a web application and users can access it from multiple devices!
    </HeartQuote>
    <HeartQuote by="Dana" mdxType="HeartQuote">
I love the ease of information transfer it provides. Having the ability to share, teach, and ultimately go full circle so you can learn yourself is astounding. More than that, the fact that you can self-teach yourself just about anything that you put your mind to. The amount of information we as a society have access to is astounding. Regarding web development, the ability to solve problems. More in-depth than that, the freedom two individuals have to solve the same exact problem in two completely different ways. That they could BOTH end up learning from how the other solved the same problem. That you never have to stand still if you want to keep learning.
    </HeartQuote>
    <HeartQuote by="Jorge" mdxType="HeartQuote">
What I love of the Web is that is the great equalizer does mater what os what browser if is a desktop tablet or phone it works.
    </HeartQuote>
    <HeartQuote by="Stephen" mdxType="HeartQuote">
I love the fantastic fact that we can do just about anything with it today. We used to be so limited but the web’s power and reach has exploded over the last few years. I love being able to create just about anything I can think of using a platform that is at almost everyone’s fingertips.
    </HeartQuote>
    <HeartQuote by="Adam" mdxType="HeartQuote">
What I love about the web is the opportunities it provides. Anyone willing to put in the work can learn how to do web development, and there are lots of great tools (like yours) that are making it easier and easier for people to get started (and interested) in web dev.
    </HeartQuote>
    <HeartQuote by="Ryan" mdxType="HeartQuote">
I love the web because of it’s ability to connect all of us and quickly share knowledge with the world. It’s pretty staggering to know that we have access to literally anything.
    </HeartQuote>
    <HeartQuote by="Rodney" mdxType="HeartQuote">
I love being able to make simple changes that cause awesome stuff to happen on (and off) screen AND those code changes can be received / updated to every user in the world "immediately"!
    </HeartQuote>
    <HeartQuote by="Carrie" mdxType="HeartQuote">
I love that it lets me create and show off my work to the entire world.
    </HeartQuote>
    <HeartQuote by="Ryan" mdxType="HeartQuote">
I love that I can build anything with just my time on the web :)
    </HeartQuote>
    <HeartQuote by="Jon" mdxType="HeartQuote">
I love that I can get my creations into everyone hands with no friction on the web!
    </HeartQuote>
    <HeartQuote by="Ghazi" mdxType="HeartQuote">
What I love about the web is that it's never boring, there's something new and exciting to explore and learn every day. We live in a special time in human history, thanks to the web.
    </HeartQuote>
    <HeartQuote by="Topher" mdxType="HeartQuote">
I love how ubiquitous the web is. How easy it is for anyone to get started and make something, learn something and level up their skills.
      <p>{`On the web, everyone has a chance to reach out and connect with other like-minded individuals and share in this wondrous thing we call life.`}</p>
    </HeartQuote>
    <HeartQuote by="Idan" mdxType="HeartQuote">
What I love about the web is its flexibility. Not just flexbox, but also how easy it is to play around, try new things, building user experiences, exploring innovations and more.
Also I love how collaborative the web dev community is.
    </HeartQuote>
    <HeartQuote by="David" mdxType="HeartQuote">
I do really love that it gives us a creative outlet, continues to challenge us and it's our way for us to improve the way users browse the web and how they interact with mobile and other various hardware devices, especially as quickly as technology and devices continue to evolve.
    </HeartQuote>
    <HeartQuote by="Eric" mdxType="HeartQuote">
I love the web because CHOICES! Oh...and cat videos...and memes.
    </HeartQuote>
    <HeartQuote by="Danny" mdxType="HeartQuote">
What I love about the web is that it allows for deeper connections and shared insights between us all floating on this rock through space!
    </HeartQuote>
    <HeartQuote by="Alex" mdxType="HeartQuote">
The thing I love most about the web is the community. When I first started digging into developing on the web and investigating it as a potential career, I found that the web development community is extremely generous with their knowledge. Most of those who have found out a new trick, method, or process write a post about it or make a screencast. This is invaluable. With such a fast-growing environment, we have to rely on each other to sift through everything and share the good stuff.
    </HeartQuote>
    <HeartQuote by="Cory" mdxType="HeartQuote">
What I love about the web is how flexible it is. I'm currently working on a progressive web application and it is just incredible what a simple website can do on a mobile phone. Also, I am really excited for other APIs, such as Bluetooth, to be implemented and widely supported. These new APIs will pave the way towards a new web!
    </HeartQuote>
    <HeartQuote by="Alan" mdxType="HeartQuote">
I love the benefits the web affords those who are passionate about developing on it.
      <p>{`The barrier to entry for new web developers is still low. Prototyping a new idea is as easy here as it is anywhere, and these two advantages have naturally led to a third: a vibrant, community of brilliant, creative minds. The web is able to foster superior community because community is built into its DNA. I can think of nowhere else that allows a person to be limited only by his or her imagination and to have access to a community as deep and wide as the web.`}</p>
    </HeartQuote>
    <HeartQuote by="Jon" mdxType="HeartQuote">
it’s wonderful, and scary, and informative, and misleading, and all sort of other adjectives but this is what makes it so amazing! 
      <p>{`The web is just like us, different is so many ways but actually all alike! `}</p>
      <p>{`Who knows but maybe if we all remembered that a little more often this world, and the world wide web, might become something even greater!`}</p>
    </HeartQuote>
    <HeartQuote by="Paul" mdxType="HeartQuote">
My favorite part about the web is how quickly it is advancing
    </HeartQuote>
    <HeartQuote by="Jason" mdxType="HeartQuote">
The ability to find an answer to any question imaginable. It's a dream for those who are curious about everything. 
    </HeartQuote>
    <HeartQuote by="Albert" mdxType="HeartQuote">
 I only have two years learning web and I know this is want to do for the rest of my life. Learning has been like a rollercoaster, there is so much to learn and life is too short!
    </HeartQuote>
    <HeartQuote by="Bram" mdxType="HeartQuote">
I love about the web that there is something new all the time, especially when you're interested in software development.
    </HeartQuote>
    <HeartQuote by="Louis" mdxType="HeartQuote">
Because developing on the web gives me the feeling like anything can be done!
    </HeartQuote>
    <HeartQuote by="Ernest" mdxType="HeartQuote">
 What I love about the open web is the community that truly facilitates inclusiveness for all willing to partake!
    </HeartQuote>
    <HeartQuote by="Zane" mdxType="HeartQuote">
What I love about the web - the wide open possibilities these days to create for others.
    </HeartQuote>
    <HeartQuote by="Shannon" mdxType="HeartQuote">
I've been a web developer for almost 25 years. What I love about the web is that it is always changing. When I started CSS and JavaScript didn't even exist and now we have all these great JavaScript frameworks.
    </HeartQuote>
    <HeartQuote by="Sean" mdxType="HeartQuote">
 I'm grateful that the internet can connects us to teachers and thought leaders for just about anything you're looking for. Whether it's things you'd use at work, home, or at play the community is there. I mean ... in the last week I've learned how to workaround the content length limitations for the JavaScript text-to-speech API, figured out when a web app will needs a message broker, and found a half-decent gluten free pizza crust recipe. What I love about the web? I love finding someone who's curating and sharing great content.
    </HeartQuote>
    <HeartQuote by="Jason" mdxType="HeartQuote">
What I love about the web is the plethora of flavors we all can code in.  It's fun learning that new library or framework that handles the mundane and allows us to be really creative in how we handle solutions.  The day I stop learning, is the day I am dead.
    </HeartQuote>
    <HeartQuote by="Brady" mdxType="HeartQuote">
I love the openness of the web. Years ago I started in more traditional programming and everything was proprietary software. Working on the front end is a complete change where almost everything is community driven and open. 
    </HeartQuote>
    <HeartQuote by="Craig" mdxType="HeartQuote">
Well I guess you could say that I’ve learned to love it… slowly. I’ve been writing code for my whole career, and started in high school about 35 years ago, so I’ve seen a lot of technology change in my time.
 
I love the democratisation of knowledge that the web has allowed – that I can literally find out anything I want.
 
From a programming perspective I love that it’s open and anybody can hack something together. I love that I can look at what somebody else has done and how they achieved it. I love how quickly I can try stuff out and let others play with it.
    </HeartQuote>
    <HeartQuote by="Daniel" mdxType="HeartQuote">
The fact that it removes barriers. Geographical, ideological, lexicographical - the web is truly a universal medium to initiate change. That's what I love about it.
    </HeartQuote>
    <h2>{`Challenge`}</h2>
    <p>{`How awesome is it that we're even `}<em parentName="p">{`able`}</em>{` to argue about things on the internet? Get your gratitude chems pumping by thinking about and sharing what you love about the web.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      